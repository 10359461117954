.MuiGrid-root.MuiGrid-container.introcontent
{
    padding: 0px 10px 40px 10px;
    margin-top: 40px;
    max-width: 75em;
    margin-left: auto;
    margin-right: auto;

}
.aboutuscontent .startimg
{

    max-width: 460px;
    margin-left: auto;

}
.introcontent .startimg
{

    max-width: 460px;
    margin-left: auto;

}
.MuiGrid-root.MuiGrid-container.introcontent-background
{
    background-color: #edecec;
    margin-top: 50px;

}

.boxcontent-right h2, .aboutuscontent h2, .boxcontent-left h2
{
    font-weight: 500;
    color: #414042;
    font-size: 26px;

}
.boxcontent-left
{
    padding-right: 30px;
    padding-bottom: 20px;

}
.boxcontent-right
{
    padding-right: 0px;
    padding-bottom: 20px;

}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 button, .boxcontent-right button, .boxcontent-left button
{
    font-family: inherit;
    background: #0060f0;
    color: #fff;
    font-weight: 600;
    text-transform: none;
    font-size: 1.125em;

}
.boxcontent-left h4, .aboutuscontent h4, .boxcontent-right h4
{
    font-weight: 400;
    color: #414042;
    font-size: 20px;
    height: 100px;
    margin : 0;

}
.MuiGrid-root.MuiGrid-container.aboutuscontent
{
    padding: 0px 10px 40px 10px;
    margin-top: 40px;
    max-width: 75em;
    margin-left: auto;
    margin-right: auto;
}
.introcontent_mobile-img
{
    display: none;
}
.introcontent-text button
{
    font-family: inherit;
    background: #0060f0;
    color: #fff;
    font-weight: 600;
    text-transform: none;
    font-size: 1.125em;

}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.introcontent-text
{
    padding-left: 8.33% !important;
    padding-right: 8.33%;
}
.MuiGrid-root.MuiGrid-container.linkscontent
{
    padding: 0px 10px 40px 10px;
    margin-top: 20px;
    max-width: 75em;
    margin-left: auto;
    margin-right: auto;
}

.introcontent-text
{
    text-align: left !important;
}
.introcontent  h1
{
    font-size: 2.4em;
    line-height: 1.12em;
    font-weight: 300;
    margin: 0 0 .48em 0;
    color: #414042;
    font-family: inherit;
    text-align: left;
    width: 560px;

}
button a{
    text-decoration: none;
    color: white;
}
.MuiGrid-root.MuiGrid-container.linkscontent{
    padding-left: 0 !important;
    padding-right: 0 !important;

}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-spacing-md-3.introcontent-background
{
    width: auto !important;
}
/** mobile design **/
@media (max-width: 600px)
{
    .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-spacing-md-3.introcontent-background
    {
        padding-right: 0 !important;
        padding-left:0 !important; ;
    }
    .MuiGrid-root.MuiGrid-container {
        width: auto !important;
        margin-left: 0!important;

    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-spacing-md-3.linkscontent, .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-spacing-md-3.aboutuscontent
    {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-spacing-md-3
    {
        width: auto !important;
        padding-left: 10px;
        padding-right: 10px;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6
    {
        padding-left: 0;
    }
    .introcontent_desktop-img
    {
        display: none;
    }
    .introcontent_mobile-img
    {
        display: block;
        margin-bottom: 24px !important;
    }
    .introcontent h1 {
        width: 330px;
        padding-left: 0px;
        font-size: 1.8em;


        padding-right: 10px;
    }
    .boxcontent-left h4, .aboutuscontent h4, .boxcontent-right h4 {
        height: auto !important;
        margin-top: 0px;
        padding-bottom: 20px;

    }
    .MuiGrid-root.MuiGrid-container.aboutuscontent {
        margin-top: 0px !important;

    }
    .introcontent-text {
        text-align: center !important;
    }
    .MuiGrid-root.MuiGrid-container.linkscontent {
        padding-bottom: 0px !important;
    }

    .aboutuscontent .startimg, .introcontent .startimg {
        margin: auto !important;


    }
    .introcontent .startimg {
        padding-top: 20px;


    }
    .login-button
    {
        display: none !important
    }
    .MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root
    {
        display: none;
    }
    .MuiGrid-root.MuiGrid-container.introcontent
    {
        padding-left: 0;
        padding-right: 0;
    }


    .MuiGrid-root.MuiGrid-container.linkscontent {
        width: auto !important;
        margin-left: 0!important;

    }
    .MuiGrid-root.MuiGrid-container.linkscontent
    {
        margin-top: 0;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.introcontent_mobile-img
    {
        padding-top: 0 !important;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.introcontent-text
    {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .MuiGrid-root.MuiGrid-container.introcontent-background {
        margin-top: 1px;
    }
    .boxcontent-left
    {
        padding-right: 0 !important;
        text-align: center;
        padding-bottom: 0 !important;

    }
    .boxcontent-right
    {
        padding-right: 0 !important;
        text-align: center;
        padding-bottom: 0 !important;


    }
    .boxcontent-right h2, .aboutuscontent h2, .boxcontent-left h2 {
        margin-bottom: 10px;
    }
}
@media ( min-width: 600px ) and ( max-width: 1000px)
{
    .aboutuscontent .startimg {
        margin: auto;
    }
    .introcontent .startimg
    {
        margin: auto;
    }

    .introcontent-text
    {
        text-align: center !important;
    }
    .boxcontent-left h4, .aboutuscontent h4, .boxcontent-right h4 {
        height: auto;
        padding-bottom: 20px;

    }
    .MuiGrid-root.MuiGrid-container.aboutuscontent
    {
        padding-left: 0;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.introcontent-text
    {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .aboutuscontent .startimg {
        max-width: 510px;
    }
    .boxcontent-right h2, .aboutuscontent h2, .boxcontent-left h2
    {
        margin-top: 5px;
        margin-bottom: 5px;

    }
    .MuiGrid-root.MuiGrid-container.aboutuscontent
    {
        margin-top: 0;
    }
    .MuiGrid-root.MuiGrid-container.linkscontent
    {
        padding-bottom: 10px;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6
    {
        margin: auto;
        padding-top: 5px;
        text-align: center;

    }
    .introcontent_desktop-img
    {
        display: none;
    }
    .introcontent_mobile-img
    {
        display: block;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.introcontent_mobile-img
    {
        margin : auto;
    }
    .introcontent h1 {
        width: 500px;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.introcontent-text
    {
        margin: auto;
    }
}
@media ( max-width: 370px)
{
    .introcontent h1 {
        width: 320px;
    }
}
@media ( max-width: 1100px) and ( min-width: 900px )
{
    .introcontent h1 {
        width: 435px;
        padding-left: 20px;
    }
}
@media ( max-width: 280px )
{
    .introcontent h1 {
        width: 250px;
        font-size: 1.5em;

    }
}
